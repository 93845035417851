import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies, ComponentGroups,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG30: '16px',
  REG40: '17px',
  REG50: '20px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '42px',
    h2: '42px',
    h3: '26px',
    h4: '26px',
    h5: '26px',
    h6: '26px',
    'content-large-breakpoint': '18px',
    content: '16px',
    button: '16px',
  },
  [ComponentGroups.HERO]: {
    title: '100px',
    'title-large-bp': '100px',
    'title-smaller-bp': '68px',
    copy: '35px',
    'copy-larger-bp': '40px',
    'copy-large-bp': '35px',
    'copy-smaller-bp': '28px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "Oswald-Bold", sans-serif',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "Oswald", sans-serif',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "Oswald", sans-serif',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "LFT Etica Sheriff", serif',
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
