module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'stovepipedeathvalley',
    siteName: 'Stovepipe Wells',
    siteUrl: 'https://www.stovepipedeathvalley.com',
    reCaptchaDBPropertyName: 'Stovepipe Wells',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    color: '#009CDB',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    robots: [],
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
    dor: {
      temperature: {
        name: '',
        api: 'weather',
      },
      liveWeather: false,
      weatherApi: 'openweathermap',
    },
    metaImageFallbackUrl: '',
  },
};
